import { MyRewardControllerService } from '@/__generated__/FrontApi';
import RewardImageBracelet100Day from '@/assets/img/img_reward_bracelet_100day.jpg';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import AddressButton from '@/components/Input/AddressButton';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import {
  FormButton,
  FormContainer,
  RightCol,
} from '@/page-blocks/auth/AuthCommon';
import { MyRewardVo } from '@/private-pages/mypage/reward-list';
import { usePopupStore } from '@/stores/PopupStore';
import { navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { fourthRegex } from '@/helpers/ValidationHelper';

const RewardForm = styled.div`
  ${FormContainer} {
    max-width: 650px;
  }
`;
const RewardImageContainer = styled.div`
  text-align: center;
  margin-bottom: 48px;
  margin-top: 24px;
  ${breakpoint(640)} {
    margin-top: 0;
  }
`;
const RewardInfoContainer = styled.p`
  strong {
    font-size: 16px;
    display: block;
    margin-bottom: 4px;
  }
  span.highlight {
    color: #e2231a;
  }
`;
const AgreeCheckFlex = styled.div`
  margin: 16px 0 32px 0;
  label {
    width: 50%;
  }
`;

interface RewardFormData {
  zipcode100days: string;
  addr1100days: string;
  addr2100days: string;
  fourthMessage: string;
  dpoAddressYn: 'Y' | 'N';
}

const Reward100AddressForm: FC<PageProps> = observer(({ location }) => {
  const popupStore = usePopupStore();
  const { donorIdEnc, authKey } = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );
  // 복호화된 donorId
  const donorId = useMemo(() => donorIdEnc && atob(donorIdEnc), [donorIdEnc]);

  const [item, setItem] = useState<MyRewardVo>();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RewardFormData>({
    defaultValues: {},
  });

  // 발송현황 기존 데이터 로드
  const loadItem = useCallback(async () => {
    try {
      const {
        data,
      } = await MyRewardControllerService.getDonationAddressInfoUsingGet({
        authKey,
        donorId,
        fourthKakaoYn: 'Y',
      });
      if (!data?.authKey) {
        alert('리워드 현황이 존재하지 않습니다.');
        navigate(`/`);
      }
      // 넘어온 값 세팅
      setItem(data);
      reset(data);
    } catch (e) {
      if (e.response) {
        popupStore.show(
          e.response.data.resultMessage || `기존 정보를 불러오지 못했습니다.`,
        );
      }
    }
  }, [authKey, donorId, popupStore, reset]);

  useEffect(() => {
    if (!donorId || !authKey) {
      popupStore.show(`필수 정보가 넘어오지 않았습니다.`);
      return;
    }
    loadItem();
  }, [authKey, donorId, loadItem, popupStore]);

  const onSubmit: SubmitHandler<RewardFormData> = useCallback(
    async (formData) => {
      // dp 테이블 우편번호 저장 안되고 있던 오류로 인하여
      // 우편번호가 없는 경우, 재입력 받을 수 있도록 수정.
      if (!formData.zipcode100days) {
        popupStore.show(`주소 입력을 확인해 주세요.`);
        return false;
      }

      const saveProcess = async () => {
        try {
          const {
            resultCode,
            resultMessage,
          } = await MyRewardControllerService.postMyAddress100DaysEditUsingPost(
            {
              ...formData,
              authKey,
              donorId,
            },
          );
          if (resultCode === `success`) {
            // 데이터 업데이트
            loadItem();
            popupStore.show(
              `감사합니다.\n팔찌 참은 각인 후 2주 내 우편을 통해 발송될 예정입니다.`,
            );
            return false;
          }

          popupStore.show(resultMessage || '');
        } catch (e) {
          if (e.response) {
            popupStore.show(
              e.response.data.resultMessage || `저장중 오류가 발생했습니다`,
            );
          }
        }
      };

      if (!formData.fourthMessage) {
        popupStore.show(
          `각인 문구를 입력하시지 않아 UNICEF가 각인된 참이 2주 내 우편으로 발송됩니다.`,
          {
            showCancelButton: true,
            onConfirm: (result: any) => {
              if (result) {
                saveProcess();
              }
              return;
            },
          },
        );
      } else {
        saveProcess();
      }
    },
    [authKey, donorId, loadItem, popupStore],
  );

  return (
    <LayoutWithoutTitle
      location={location}
      title="발송 현황"
      hideGnb
      hideMargin
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <RewardForm className="form-container">
          <RewardImageContainer>
            <img src={RewardImageBracelet100Day} alt="리워드 이미지" />
          </RewardImageContainer>
          <FormContainer>
            <FormGroup>
              <RewardInfoContainer>
                <strong>
                  <span className="ellipsis">{item?.donorName}</span> 후원자님,
                </strong>
                유니세프 팀 합류 100일 기념 참을 수령하실{' '}
                <span className="highlight">
                  주소지 입력 후 2주 내로 우편을 통해 발송
                </span>
                됩니다.
                <br />
                주소지를 입력하지 않으실 경우, 참은 발송되지 않습니다.
              </RewardInfoContainer>
            </FormGroup>
            <FormGroup>
              <LabelText require>주소</LabelText>
              <Row>
                <Col desktop="auto">
                  <InputText name="zipcode100days" ref={register} readOnly />
                </Col>
                <RightCol desktop="none">
                  <AddressButton
                    onSelectAddress={(address) => {
                      setValue(`zipcode100days`, address.zonecode);
                      setValue(`addr1100days`, address.address);
                    }}
                    renderButton={(buttonProps) => (
                      <FormButton {...buttonProps}>주소검색</FormButton>
                    )}
                  />
                </RightCol>
              </Row>
              <Row>
                <InputText
                  name="addr1100days"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  readOnly
                />
              </Row>
              {errors.addr1100days && (
                <ErrorMessage>{errors.addr1100days.message}</ErrorMessage>
              )}
              <Row>
                <InputText
                  name="addr2100days"
                  maxLength={100}
                  ref={register({
                    required: {
                      value: true,
                      message: `주소를 입력해주세요.`,
                    },
                  })}
                />
              </Row>
              {errors.addr2100days && (
                <ErrorMessage>{errors.addr2100days.message}</ErrorMessage>
              )}
              {/*
              <Row>
                <FormTextTip>
                  주소지는{` `}
                  <span className="ellipsis">발송예정일 7일 전까지</span>
                  {` `}
                  수정 가능하며 이후에는 수정이 불가능합니다.
                </FormTextTip>
              </Row>
              */}
            </FormGroup>
            <FormGroup>
              <LabelText require>팔찌 참 각인 문구</LabelText>
              <Row>
                <InputText
                  name="fourthMessage"
                  placeholder="영어 대문자 1~5 자 입력"
                  maxLength={5}
                  ref={register({
                    validate: {
                      isValidRange: (value) => {
                        return (
                          !value ||
                          fourthRegex.test(value) ||
                          `* 각인 문구는 영어 알파벳 대문자 1~5자만 입력 가능합니다.`
                        );
                      },
                    },
                  })}
                />
              </Row>
              {errors.fourthMessage ? (
                <ErrorMessage>{errors.fourthMessage.message}</ErrorMessage>
              ) : (
                <FormTextTip>
                  각인 문구는 영어 알파벳 대문자 1~5자만 입력 가능합니다.
                </FormTextTip>
              )}
              <FormTextTip>
                각인 문구를 입력하지 않으실 경우, UNICEF가 각인된 참이
                발송됩니다.
              </FormTextTip>
            </FormGroup>
            <FormGroup>
              <LabelText>
                <strong>입력하신 주소를 후원자 정보에 저장하시겠습니까?</strong>
              </LabelText>
              <AgreeCheckFlex>
                <InputRadio
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  label="예"
                  name="dpoAddressYn"
                  value="Y"
                />
                <InputRadio
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  label="아니오"
                  name="dpoAddressYn"
                  value="N"
                />
              </AgreeCheckFlex>
              {errors.dpoAddressYn && (
                <ErrorMessage>{errors.dpoAddressYn.message}</ErrorMessage>
              )}
              <FormTextTip>
                '예'를 선택하시면 입력하신 주소를 후원자 정보에 저장합니다.
              </FormTextTip>
              <FormTextTip>
                '아니오'를 선택하시면 배송 완료 후 파기합니다.
              </FormTextTip>
            </FormGroup>
            <Button full type="submit">
              입력완료
            </Button>
          </FormContainer>
        </RewardForm>
      </form>
    </LayoutWithoutTitle>
  );
});

export default Reward100AddressForm;
